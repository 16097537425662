import React from 'react'
import Layout from '../components/layout'
import PageHeading from '../UI/PageHeading/PageHeading'
import PageTransition from 'gatsby-plugin-page-transitions'
import { Text } from './../UI/Typing'
import Animotion from './../UI/Animotion'
import { FormattedMessage } from 'gatsby-plugin-intl'
import './cook.styl'

import HeadImg from './../Assets/images_pg/cook/bar.jpg'
import PrimoImg from './../Assets/images_pg/cook/006.jpg'
import SecondoImg from './../Assets/images_pg/cook/cook1.jpg'
import DolceImg from './../Assets/images_pg/cook/DSC_0795.jpg'

const CookRow = props => (
  <div className={`CookRow ${props.position}`}>
    <div>
      <figure style={{ backgroundImage: `url(${props.image})` }} />
    </div>
    <div>
      <Text>{props.text}</Text>
    </div>
  </div>
)

const CookPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PageTransition>
      <PageHeading
        image={HeadImg}
        title={<FormattedMessage id='cook' />}
        subtitle={<FormattedMessage id='cookSubtitle' />}
        text={<FormattedMessage id='cookDescription' />}
      />
      <section>
        <Animotion>
          <CookRow
            image={PrimoImg}
            title={<FormattedMessage id='primiTitle' />}
            text={<FormattedMessage id='primiDescription1' />}
            position='left'
          />
        </Animotion>
        <Animotion>
          <CookRow
            image={SecondoImg}
            title={<FormattedMessage id='secondiTitle' />}
            text={<FormattedMessage id='secondiDescription' />}
            position='right'
          />
        </Animotion>
        <Animotion>
          <CookRow
            image={DolceImg}
            title={<FormattedMessage id='dolciTitle' />}
            text={<FormattedMessage id='dolciDescription' />}
            position='left'
          />
        </Animotion>
      </section>
    </PageTransition>
  </Layout>
)

export default CookPage
